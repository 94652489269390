<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <UserForm />
  </main>
</template>

<script>
import UserForm from '../../../components/admin/user/UserForm.vue';

export default {
  components: { UserForm },

  metaInfo() {
    return { title: 'Tambah' };
  },

  beforeMount() {
    this.$store.commit('info/setBreadcrumb', [
      { path: '/admin/user', label: 'User' },
    ]);
  },
};
</script>
